.sidenav {
  height: 100%;
  width: 61px;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  background-color: #113dbc;
  padding-top: 5.5%;
}

.sidebar-toggle {
  position: absolute;
  top: 4.5%;
  cursor: pointer;
  right: -10px;
}

.sidenav a {
  padding: 15px 5px;
  text-decoration: none;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidenav span {
  display: flex;
  color: #d9e8ec;
  justify-content: center;
  font-size: 0.7rem;
}

.sidenav.expanded a {
  padding: 20px 20px;
  text-decoration: none;
  font-size: 13px;
  display: flex;
  justify-content: left;
  align-items: center;
}

.sidebar-img-div {
  width: 100%;
  text-align: center;
}

.sidebar-switch .sidebar-img-div {
  width: 25px;
  text-align: center;
}

.expanded .sidebar-img-div {
  width: 20%;
  text-align: center;
}

.expanded .expanded-sidebar-item {
  color: #ffffff;
  display: block;
  margin-left: 10px;
  white-space: nowrap;
  font-size: 13px;
  width: 80%;
}

.sidenav a.selected {
  background: #000615;
}

.sidebar-switch {
  position: absolute;
  bottom: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.sidebar-switch .dropright .dropdown-toggle::after {
  display: inline-block;
}

.sidebar-switch .dropdown-item {
  font-size: 11px !important;
  padding: 10px 15px !important;
  display: flex;
  justify-content: left;
}

.sidebar-switch .dropdown-menu {
  border: none;
}

.org-switch-icon {
  display: inline-block !important;
}

.org-switch-icon img {
  width: 24px;
}

#dropdown-switch {
  padding: 18px 5px;
  margin-left: 5px;
}

.sidenav.expanded .sidebar-switch a.org-switch-icon {
  justify-content: left;
  padding: 0;
  margin-left: 5px;
}

.sidenav.expanded .sidebar-switch a.org-switch-icon:hover {
  background: transparent;
}

.expanded-sidebar-item {
  display: none;
}

.expanded {
  width: 200px !important;
  transition: 0.2s;
}

.collapsed {
  transition: 0.2s;
}

.sidenav.expanded a:hover {
  background: #364d5a;
}

.expanded .sidebar-switch a:hover {
  background: #f0f0f0;
}

.sidenav-links {
  height: 48vh;
}

.sidenav-links a {
  margin: 0px 0px;
}

.sidenav-links a.active {
  background: #009cf6;
  width: 70%;
  border-radius: 30px;
  height: 46px;
  margin-left: 10px;
}

.sidenav-links a.active i {
  color: #ffffff !important;
}

.sidebar-switch .dropdown-item:hover {
  color: #222;
}

.sidebar-switch .dropdown-item.active {
  background: #fff;
  color: #222;
}

.sidebar-org-setting {
  position: absolute;
  bottom: 5%;
  left: 1px;
}

.sidebar-org-setting > a {
  width: 75% !important;
  height: unset !important;
  margin-left: 10px;
}

.sidebar-logo {
  visibility: hidden;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
}

.sidebar-logo img {
  width: 50%;
  padding: 10px;
}
