@import "./styles/bootstrap.scss";

body {
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
  color: #222222;
  line-height: 1.7;
  background-color: #ffffff;
}

::-webkit-scrollbar {
  height: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

/* Website with Archivo */
.m,
.D {
  font-family: "Inter", sans-serif;
}

/* Buttons */
.btn-aw-white {
  background: white;
  padding: 7px 20px;
  border-radius: 4px;
  color: #1f2d3d;
  text-decoration: none;
  cursor: pointer;
}

.btn-aw-blue:hover,
.btn-aw-blue {
  color: white;
  padding: 7px 20px;
  border-radius: 4px;
  background-color: #021956;
  text-decoration: none;
  border: 1px solid white;
  cursor: pointer;
}

.aw-white-btn,
.aw-white-btn:hover {
  background: white;
  padding: 7px 20px;
  border-radius: 4px;
  color: #1f2d3d;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid #d9e8ec;
}

.btn-aw-blue2:hover,
.btn-aw-blue2 {
  color: white;
  padding: 5px 20px;
  border-radius: 4px;
  background-color: #113dbc;
  text-decoration: none;
  border: 1px solid white;
  cursor: pointer;
}

.btn-aw-blue2.fr[disabled] {
  background: #7b90ce;
}

/* Headings */

/* h5 bold */
.s5 {
  font-weight: bold;
}

/* colors */

.heading2 {
  font-size: 14px;
  color: #9dafb0;
}

.heading3 {
  font-size: 16px;
  color: #1f2d3d;
  font-weight: 500;
}

.fr {
  float: right;
}

.cgrey {
  color: #1f2d3d;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.bolder {
  font-weight: 900;
}

#our-process {
  background-image: url("https://awign-production.s3.ap-south-1.amazonaws.com/clients-ui/hero_dark.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -120px;
  color: #fff;
}
@media (max-width: 1199px) {
  .c-section.-space-large {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.c-section {
  position: relative;
  background-color: #ffffff;
}
@media (min-width: 576px) {
  .c-container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.c-container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1200px) {
  .c-section.-space-large {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}
.pb-50 {
  padding-bottom: 50px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.u-text-lead {
  font-size: 16px !important;
  font-weight: 300 !important;
  color: #3c4858;
}

.c-logos-1.-style-default .c-logos-1-inner {
  overflow: hidden;
}

.row.-gutter-none {
  margin-left: 0;
  margin-right: 0;
}

.ph-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

.topbar-brand {
  width: 100px;
}

.header-icon-profile {
  width: 20px;
}

.input-validation-error-p {
  color: #dd1414 !important;
  font-size: 12px;
  margin-top: 5px;
}

.input-validation-error-p > p {
  margin-bottom: 0px;
}

.text-danger {
  font-size: 12px;
}

.shade-background {
  background: #f5fafa;
  min-height: 100vh;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

#app {
  min-height: 100vh;
  height: 100vh;
}

.layout_wrapper {
  padding-top: 50px;
}

.white-background {
  background: #ffffff;
}

.contracted-row {
  margin-left: 45px !important;
}

.thick-border {
  border: 4px solid #eef2f7;
}

.custom-loader {
  position: absolute;
  top: 43%;
  right: 47.1%;
  opacity: 0.8;
}

.parent_global_success {
  color: #fff;
  background: #13ce66;
  text-align: center;
  padding: 15px 50px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  position: fixed;
  right: 0;
  top: 54px;
  opacity: 0.95;
  left: 0;
  z-index: 1060;
  margin: 0 auto;
  word-break: break-word;
}

.parent_global_info {
  color: #fff;
  background: #ae8bff;
  text-align: center;
  padding: 15px 50px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  position: fixed;
  right: 0;
  top: 54px;
  opacity: 0.95;
  left: 0;
  z-index: 1050;
  margin: 0 auto;
  word-break: break-word;
}

.parent_global_error {
  color: #fff;
  background: #ff4949;
  text-align: center;
  padding: 15px 50px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  position: fixed;
  right: 0;
  top: 54px;
  opacity: 0.95;
  left: 0;
  z-index: 1060;
  margin: 0 auto;
  word-break: break-word;
}

.flex-centring-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-centring-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.delete-error {
  position: absolute;
  right: 30px;
  cursor: pointer;
}

.brand-logo {
  width: 105px;
}

.header-left-text {
  margin-left: 40px;
  font-weight: 900;
}

.pointer {
  cursor: pointer !important;
}

.cross-icon {
  cursor: pointer;
  float: right;
  margin-bottom: 20px;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.dashed-outline {
  border: 1px dashed #ddd;
  padding: 10px;
}

.dashed-outline-sm {
  border: 1px dashed #ddd;
  padding: 5px;
}

.dashed-outline-xs {
  border: 1px dashed #ddd;
  padding: 2px;
}

.btn-hollow {
  background: transparent;
  border: 1px solid #ccc !important;
  color: #fff;
}

.btn-hollow:hover {
  color: #aaa !important;
}

.btn-hollow-dark {
  background: transparent;
  border: 1px solid #a0a1a5 !important;
  color: #465169;
  font-weight: 500;
  padding: 10px;
}

.btn-hollow-dark:hover {
  color: #101010 !important;
  border: 1px solid #303031 !important;
}

.btn-hollow-blue {
  background: transparent;
  border: 1px solid #009cf6 !important;
  color: #009cf6;
  font-weight: 400;
  padding: 10px;
}

.btn-hollow-blue:hover {
  color: #265eff !important;
  border: 1px solid #265eff !important;
}

.full-width {
  width: 100%;
}

.width-95p {
  width: 95%;
}

.no-internet-span {
  position: fixed;
  background: #707707;
  z-index: 9999;
  color: #fff;
  padding: 20px;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
}

.font-18 {
  font-size: 18px !important;
}

.font-17 {
  font-size: 17px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-11 {
  font-size: 11px !important;
}

.input-validation-error > p {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

/* Loader */

.loader {
  height: 20px;
  width: 250px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 10px auto 30px auto;
}
.loader--dot {
  animation-name: loader;
  animation-timing-function: ease-in-out;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: black;
  position: absolute;
  border: 2px solid white;
}
.loader--dot:first-child {
  background-color: #1b8383;
  animation-delay: 0.5s;
}
.loader--dot:nth-child(2) {
  background-color: #265eff;
  animation-delay: 0.4s;
}
.loader--dot:nth-child(3) {
  background-color: #5dadef;
  animation-delay: 0.3s;
}
.loader--dot:nth-child(4) {
  background-color: #6389fc;
  animation-delay: 0.2s;
}
.loader--dot:nth-child(5) {
  background-color: #60beeb;
  animation-delay: 0.1s;
}
.loader--dot:nth-child(6) {
  background-color: #928800;
  animation-delay: 0s;
}
.loader--text {
  position: absolute;
  top: 200%;
  left: 0;
  right: 0;
  width: 10rem;
  margin: auto;
}
.loader--text:after {
  content: "Please wait";
  font-weight: bold;
  animation-name: loading-text;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes loader {
  15% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(230px);
  }
  65% {
    transform: translateX(230px);
  }
  95% {
    transform: translateX(0);
  }
}
@keyframes loading-text {
  0% {
    content: "Please wait";
  }
  25% {
    content: "Please wait.";
  }
  50% {
    content: "Please wait...";
  }
  75% {
    content: "Please wait....";
  }
}

.hide {
  display: none !important;
}

.text-secondary {
  color: #7898f5;
}

.block {
  display: block;
}

.footer-text {
  color: inherit;
  font-size: 13px;
  text-align: center;
}

.line-height-2 {
  line-height: 2rem;
}

.no-text {
  text-align: center;
  font-size: 15px;
  margin: 20px 0;
  color: #aaa;
}

.bold {
  font-weight: 600;
}

.custom-checkbox {
  line-height: 1.6rem;
  color: #666;
}

.selected_elements__multi-value__label {
  color: #3e9df6 !important;
  font-size: 95% !important;
  background: #eef1fd !important;
  border-radius: 2px 0 0 2px !important;
}

.selected_elements__multi-value__remove {
  background: #eef1fd !important;
  border-radius: 2px 2px 0 0 !important;
}

.selected_elements__multi-value__remove:hover {
  background: #e1e6fd !important;
}

/* React Tags */
div.ReactTags__tags {
  position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
  border-radius: 2px;
  display: block;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: calc(2.25rem + 2px);
  margin: 5px 0;
  font-size: 0.85rem;
  width: 100%;
  font-weight: 400;
  padding: 0.275rem 0.5rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #cce5fd;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px 5px 0;
  cursor: move;
  border-radius: 17px;
}

div.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

.full-width-modal {
  width: 90vw; /* occupy 95% of the screenwith */
  max-width: 90vw;
}

.half-width-modal {
  width: 50vw; /* Occupy the 75% of the screen width */
  max-width: 50vw;
}

.semi-full-width-modal {
  width: 75vw; /* Occupy the 50% of the screen width */
  max-width: 75vw;
}

.hint-text {
  color: #aaa;
  padding: 5px 0;
  display: block;
}

.move {
  cursor: move;
}

.not-allowed-pointer {
  cursor: not-allowed !important;
}

.confirmation-modal .modal-header {
  border-bottom: none;
  padding: 1.5rem 1.5rem 0.8rem 1.5rem;
}

.confirmation-modal .modal-body {
  padding: 1rem 1.5rem 0 1.5rem;
}

.confirmation-modal .modal-footer {
  border-top: none;
  justify-content: flex-start;
  padding: 0 1.5rem 1.5rem 1.5rem;
}

.preview-img-lg {
  width: 150px;
  padding: 1rem;
  border-radius: 4px;
  border: 1px solid #b7cfe0;
}

.preview-img-sm {
  width: 80px;
  padding: 0.75rem;
  border-radius: 4px;
  border: 1px solid #b7cfe0;
  margin-bottom: 4px;
}

.preview-img-xs {
  width: 40px;
  padding: 0.5rem;
  border-radius: 2px;
  border: 1px solid #b7cfe0;
}

.header-actions {
  display: flex;
}

.half-input-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
}

.half-input-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #bbeebb;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.inside-icon {
  position: absolute;
  margin-top: -22px;
  right: 20px;
}
.disabled {
  opacity: 0.5;
}
.absolute-pos {
  position: absolute;
}
.center-content-div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.helper-img-view {
  width: 110%;
  position: relative;
  padding: 50px 20px;
  margin-left: 25%;
}
.float-close-btn {
  position: absolute;
  top: 30px;
  right: 50px;
  cursor: pointer;
}

.MuiFormHelperText-root {
  color: rgba(107, 6, 123, 0.54) !important;
}
.material-select-box {
  position: absolute;
  top: 75px;
  font-size: 12px;
  background: #fff;
  z-index: 99;
  padding: 0px 5px;
  margin-left: 12px;
}
.shake-entity {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  opacity: 0.5;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.header-icons {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.header-icon-profile {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.dropdown-icons {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.left-menu .dropdown-menu {
  left: auto !important;
  right: 0;
}

.with-header .dropdown-item:first-child {
  font-weight: 500;
}

.header-separator {
  border-right: 1px solid #2d3238;
  padding-right: 20px;
}

.topbar-brand {
  max-width: 100px;
  max-height: 38px;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  color: #ffffff;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.footer {
  display: flex;
  position: absolute;
  bottom: 0px;
  left: 0;
  justify-content: center;
  width: 100%;
}

.push-end {
  display: inline-flex;
  justify-content: flex-end;
}

.close-cta {
  float: right;
  margin: 7px 50px;
  color: #3b475a;
  cursor: pointer;
}

.breadcrumb-data {
  background-color: #5935b1 !important;
}

.breadcrumb-data .breadcrumb-item a {
  color: #b5badd;
}

.breadcrumb-data .breadcrumb-item span {
  color: #ffffff;
}

.breadcrumb-data .breadcrumb-item + .breadcrumb-item::before {
  color: #b5badd;
}

.breadcrumb-list {
  background-color: #3747af !important;
}

.breadcrumb-list .breadcrumb-item a {
  color: #b5badd;
}

.breadcrumb-list .breadcrumb-item span {
  color: #ffffff;
}

.breadcrumb-list .breadcrumb-item + .breadcrumb-item::before {
  color: #b5badd;
}

.breadcrumb-summary {
  background-color: #267ed5 !important;
}

.breadcrumb-summary .breadcrumb-item a {
  color: #d6e4f2;
}

.breadcrumb-summary .breadcrumb-item span {
  color: #ffffff;
}

.breadcrumb-summary .breadcrumb-item + .breadcrumb-item::before {
  color: #d6e4f2;
}

.btn-summary-view {
  border-color: #0a5fb1;
  background: #0a5fb1;
  border-radius: 20px;
}

.btn-summary-view:hover {
  border-color: #0a5fb1;
  background: #0a5fb1;
}

.btn-summary-view:focus,
.btn-summary-view.focus {
  box-shadow: 0 0 0 0.01rem rgba(3, 5, 133, 0.2);
}

.btn-summary-view.disabled,
.btn-summary-view:disabled {
  border-color: #0a5fb1;
  background: #0a5fb1;
}

.btn-list-view {
  border-color: #273488;
  background: #273488;
  border-radius: 20px;
}

.btn-list-view:hover {
  border-color: #273488;
  background: #273488;
}

.btn-list-view:focus,
.btn-list-view.focus {
  box-shadow: 0 0 0 0.01rem rgba(3, 5, 133, 0.2);
}

.btn-list-blue.disabled,
.btn-list-view:disabled {
  border-color: #273488;
  background: #273488;
}

.btn-data-view {
  border-color: #3e1f8d;
  background: #3e1f8d;
  border-radius: 20px;
}

.btn-data-view:hover {
  border-color: #3e1f8d;
  background: #3e1f8d;
}

.btn-data-view:focus,
.btn-data-view.focus {
  box-shadow: 0 0 0 0.01rem rgba(3, 5, 133, 0.2);
}

.btn-data-blue.disabled,
.btn-data-view:disabled {
  border-color: #3e1f8d;
  background: #3e1f8d;
}

.floating-alert {
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -50px;
}

.cell-data {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline;
  font-size: 12px;
}

.total-info {
  position: absolute;
  left: 13px;
  font-weight: 500;
  color: #343a40;
  font-size: 1rem;
}

/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cbced3;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #afb1b0;
}

.error-text {
  color: red;
  font-size: 11px;
}

/* Go Back */

.go-back {
  color: #8ea2a5 !important;
  margin-left: 7px;
  font-size: 12px;
}

.btn-wb-primary {
  background-color: #113dbc;
  color: white;
}

.btn-wb-primary:hover {
  color: white;
}

.wb-secondary-btn {
  background-color: white;
  color: #113dbc;
  border: 1px solid #e0e6ed;
  border-radius: 8px;
}

.wb-secondary-btn:hover {
  background-color: white;
  color: #092fa0;
  border: 1px solid #e0e6ed;
  border-radius: 8px;
}

.react-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
}
.react-tagsinput--focused {
  border-color: #675fda;
}
.react-tagsinput-tag {
  background-color: #eff2f7;
  border-radius: 20px;
  border: 1px solid #e0e6ed;
  color: #1f2d3d;
  display: inline-block;
  font-size: 11px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px 10px;
}
.react-tagsinput-remove {
  cursor: pointer;
  background: #fff;
  font-weight: bold;
  margin-left: 10px;
  padding: 0px 4px 0px 0;
  border-radius: 10px;
}
.react-tagsinput-tag a::before {
  content: " ×";
}
.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-size: 11px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: 150px;
}

.leads-page-wrapper {
  padding-top: 50px;
}

.mobile-layout-wrapper {
  background-color: #113dbc;
  color: white;
  padding: 31px 0 32px 12px;
  font-size: 1.5em;
  position: relative;
  z-index: 2;
}

.bg-blue {
  background-color: #113dbc;
}

.bg-dark-white {
  background-color: #f4f9fa;
}

.relative {
  position: relative;
}

.header-img {
  width: 85px;
  height: 25px;
  background-size: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.center-y {
  display: flex;
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

.light-gray {
  color: #606a7b;
}
.light-purple {
  background: #ecf1fd;
}

.black {
  color: #1f2d3d;
}

.font-12 {
  font-size: 12px;
}

.filters_input img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.filters_input input {
  padding-left: 35px;
}

.clear_all {
  font-size: 12px;
  padding: 8px 30px;
  border-radius: 4px;
  border: solid 1px #d9e8ec;
  background-color: #ffffff;
  cursor: pointer;
}

.apply_filters {
  color: white;
  font-size: 12px;
  padding: 8px 30px;
  border-radius: 4px;
  border: none;
  background-color: #49be90;
  cursor: pointer;
}

.search_input {
  border-radius: 4px;
  border: solid 1px #d9e8ec;
  padding: 7px 40px;
  width: 400px;
}

.search_icon {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}

.max-60vh {
  max-height: 60vh;
}
.overflow-y-scroll {
  overflow-y: auto;
}
.fw-300 {
  font-weight: 300;
}

.fw-600 {
  font-weight: 600;
}
.fw-500 {
  font-weight: 500;
}

.br-8 {
  border-radius: 8px;
}

.br-12 {
  border-radius: 12px;
}

.black {
  color: #1f2d3d;
}

.black-2 {
  color: #3c4858;
}

.blue {
  color: #113dbc;
}

.gray-1 {
  color: #9cafb1 !important;
}

.fw-700 {
  font-weight: 700;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.font-24 {
  font-size: 24px;
}
.font-32 {
  font-size: 32px;
}

.font-40 {
  font-size: 40px;
}

.font-48 {
  font-size: 48px;
}
